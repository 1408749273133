import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Image } from 'react-bootstrap';
import moment from "moment/moment";
import gForceImg from '../assets/gForced-marker.svg';
import batteryLevelImg from '../assets/battery-marker.svg';
import lightLevelImg from '../assets/opened-marker.svg';
import tempColderImg from '../assets/temp-colder-marker.svg';
import tempWarmerImg from '../assets/temp-warmer-marker.svg';
import gForceLegend from '../assets/gForced-legend.svg';
import lightLevelLegend from '../assets/opened-legend.svg';
import tempColderLegend from '../assets/temp-colder-legend.svg';
import tempWarmerLegend from '../assets/temp-warmer-legend.svg';
import batteryLevelLegend from '../assets/batteryLevel.svg';
import destinationFlag from '../assets/destinationIcon.svg';
import currentLocation from '../assets/current_location.svg';
import locationImg from '../assets/location.svg';
import infoIcon from '../assets/info-icon.png';
import noDataFoundImg from '../assets/no-data-found.png';
import "./CommonComponent.css";
import { EventType } from '../DeviceDetails/DeviceDetails';

// TODO: will remove hard code value later in future state
const CLIENT_ID = 1;

const LastUpdatedText = (datetime) => {
    if (datetime !== null) {
        var yesterday = moment().subtract(1, 'days').startOf('day');
        if (moment(datetime).isSame(Date.now(), 'day')) {
            return `Today ${moment(datetime).format(' @ h:mm A')}`;
        } else if (moment(datetime).isSame(yesterday, 'day')) {
            return`Yesterday ${moment(datetime).format(' @ h:mm A')}`;
        } else {
            return moment(datetime).format('MMMM DD @ h:mm A');
        }
    } else {
        return "N/A";
    }
}

const DeviceStatus = (inFlight) => {
    return <>
        <p className={` status ${!inFlight ? "ready" : ""}`}>
            {inFlight ? "In flight" : "Ready"}
        </p>
    </>;
}

const DeviceEventText = (eventType, eventValue, avgTemp) => {
    var eventText = '';
    if (eventType === "temp") {
        if (eventValue <= avgTemp) {
            const tempDiff = (avgTemp - eventValue).toFixed(2)
            eventText = `Temperature dropped more than ${tempDiff} °C below the trips average of ${Math.ceil(avgTemp)} °C`;
        } else {
            const tempDiff = (eventValue - avgTemp).toFixed(2)
            eventText = `Temperature rose more than ${tempDiff} °C above the trips average of ${Math.ceil(avgTemp)} °C`;
        }
    } else if (eventType === 'battery') {
        eventText = `Battery level is ${eventValue}% or lower`;
    } else if (eventType === "gForce") {
        eventText =  "Shipment may have experienced some sort of impact";
    } else if (eventType === "lightLevel") {
        eventText = "The shipment appears to have been opened";
    }
    return eventText;
}

const NoDataFoundContainer = () => {
    return <div className='no-data-container'>
        <Image fluid src={noDataFoundImg}></Image>
        <span>No data found</span>
    </div>;
}

const GoogleMarkersDesc = () => {
    return <>
        <Row className='p-2 m-0'>
            <Col className='d-flex align-items-start'>
                <Image fluid src={batteryLevelImg}></Image>
                <p className='marker-desc'>Battery</p>
            </Col>
            <Col className='d-flex align-items-start'>
                <Image fluid src={gForceImg}></Image>
                <p className='marker-desc'>Impact</p>
            </Col>
            <Col className='d-flex align-items-start'>
                <Image fluid src={tempColderImg}></Image>
                <p className='marker-desc'>Low Temp</p>
            </Col>
            <Col className='d-flex align-items-start'>
                <Image fluid src={tempWarmerImg}></Image>
                <p className='marker-desc'>High Temp</p>
            </Col>
            <Col className='d-flex align-items-start'>
                <Image fluid src={lightLevelImg}></Image>
                <p className='marker-desc'>Opened</p>
            </Col>
            <Col className='d-flex align-items-start'>
                <Image fluid src={destinationFlag}></Image>
                <p className='marker-desc'>Destination</p>
            </Col>
            <Col className='d-flex align-items-start'>
                <Image fluid src={currentLocation}></Image>
                <p className='marker-desc'>Latest Location</p>
            </Col>
        </Row>
    </>
}

const GetMarkerIconByEvent = (event, filters) => {
    var googleMapsMarkerUrl = locationImg;
    if (event) {
        if (event.passThreshold !== undefined && event.passThreshold === true) {
            if (event.lightLevel !== null && filters.includes(EventType.LightLevel)) {
                googleMapsMarkerUrl = lightLevelImg;
            } else if (event.battery !== null && filters.includes(EventType.Battery)) {
                googleMapsMarkerUrl = batteryLevelImg;
            } else if (event.gForce !== null && filters.includes(EventType.GForce)) {
                googleMapsMarkerUrl = gForceImg;
            } else if (event.temperature !== null) {
                if (event.temperature > event.avgTemp && filters.includes(EventType.HighTemp)) {
                    googleMapsMarkerUrl = tempWarmerImg;
                } else if (event.temperature < event.avgTemp && filters.includes(EventType.LowTemp)){
                    googleMapsMarkerUrl = tempColderImg;
                }
            }
        }
    }
    return googleMapsMarkerUrl;
}

const SegmentedControl = ({
    name,
    segments,
    callback,
    defaultIndex = 0,
    controlRef
  }) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const componentReady = useRef();
  
    // Determine when the component is "ready"
    useEffect(() => {
      componentReady.current = true;
    }, []);
  
    useEffect(() => {
      const activeSegmentRef = segments[activeIndex].ref;
      const { offsetWidth, offsetLeft } = activeSegmentRef.current;
      const { style } = controlRef.current;
  
      style.setProperty("--highlight-width", `${offsetWidth}px`);
      style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }, [activeIndex, callback, controlRef, segments]);
  
    const onInputChange = (value, index) => {
      setActiveIndex(index);
      callback(value, index);
    };
  
    return (
      <div className="controls-container" ref={controlRef}>
        <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
          {segments?.map((item, i) => (
            <Col
              key={item.value}
              className={`segment ${i === activeIndex ? "active" : "inactive"} ${i === 0 ? "border-radius-left" : ""} ${i === segments.length - 1 ? "border-radius-right" : ""}`}
              ref={item.ref}
              sm={4}
            >
                <input
                    type="radio"
                    value={item.value}
                    id={item.label}
                    name={name}
                    onChange={() => onInputChange(item.value, i)}
                    checked={i === activeIndex}
                />
                <label htmlFor={item.label}>
                    <span>
                        <FontAwesomeIcon icon={faCheck} className={`check-icon ${i === activeIndex ? "active" : "inactive"}`}/>
                    </span>
                {item.label}
                </label>
            </Col>
          ))}
        </div>
      </div>
    );
};

const TrackingProgressBar = ({ 
    currentStatus,
}) => {
    const statuses = ["Picked up", "In transit", "Delivered"]
    return (
        <Row className="tracking-bar">
            {statuses?.map((item, i) => (
                <Col key={i} className='tracking-bar-col'>
                    <div 
                        className={`progress-bar ${i <= currentStatus ? "active" : "inactive"} ${i === 0 ? "border-left" :  i === statuses.length - 1 ? "border-right" : ""}`}>
                    </div>
                    <p>{item}</p>
                </Col>
            ))}
        </Row>
    );
}

const BatteryLevel = (battery) => {
    return <>
        <div className='d-flex battery'>
            <div className='battery-outside-container'>
                <div className={`battery-inside-container ${battery <= 20 ? "low" : battery >= 80 ? "high" : "medium"}`}></div>
            </div>
            <div className='battery-right-container'></div>
        </div>
    </>
}

const filteringOptions = [
    {
        label: "Battery low",
        textBeforeThreshold: "Under ",
        threshold: "15%",
        textAfterThreshold: " charge",
        img: batteryLevelLegend
    },
    {
        label: "Impact",
        textBeforeThreshold: "Over ",
        threshold: "30",
        textAfterThreshold: " m/s/s",
        img: gForceLegend
    },
    {
        label: "Opened",
        textBeforeThreshold: "",
        threshold: "Package opened",
        textAfterThreshold: "",
        img: lightLevelLegend
    },
    {
        label: "High Temperature",
        textBeforeThreshold: "Over ",
        threshold: "Temp Average + 7°C",
        textAfterThreshold: "",
        img: tempWarmerLegend
    },
    {
        label: "Low Temperature",
        textBeforeThreshold: "Under ",
        threshold: "Temp Average - 7°C",
        textAfterThreshold: "",
        img: tempColderLegend
    }
]
const FilteringOptionInfo = () => {
    return <div className={``} id="filter-options-container">
        <>
            <Image fluid src={infoIcon}></Image>
            <Tooltip place="bottom" anchorSelect="#filter-options-container" clickable style={{background: "var(--white)", borderRadius: "8px", color: "black", maxWidth:"430px"}}>
                <div className='p-2'>
                    <p><span className='threshold'>Filters</span> will show the events that occur within the threshold limits</p>
                    {filteringOptions.map((option, index) => (
                        <div key={index}>
                            <hr className='option-divider'/>
                            <div className='d-flex'>
                                <Image fluid src={option.img} className='option-icon'></Image>
                                <div className='option-info'>
                                    <Row className='option-info'>
                                        <Col>
                                            <p className='label'>{option.label}</p>
                                        </Col>
                                        <Col className='option-col'>
                                            <p className='option-threshold'>
                                                <span>{option.textBeforeThreshold}</span> 
                                                <span className='threshold'>{option.threshold}</span> 
                                                <span>{option.textAfterThreshold}</span>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Tooltip>
        </>
    </div>
}

export { 
    LastUpdatedText, 
    DeviceStatus, 
    SegmentedControl, 
    TrackingProgressBar, 
    BatteryLevel, 
    DeviceEventText, 
    GetMarkerIconByEvent,
    GoogleMarkersDesc,
    NoDataFoundContainer,
    FilteringOptionInfo,
    CLIENT_ID
}