import React, { useState, useEffect, useRef } from "react";
import { GetDevices, Status, GetDeviceType } from "../api/devices";
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import {
    useReactTable,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom"
import DeviceListSkeleton from "./DeviceListSkeleton";
import { LastUpdatedText, DeviceStatus, CLIENT_ID } from "../components/CommonComponent"
import '../DeviceList/DeviceList.css';

var originalDevicesList = [];

const DeviceList = () => {
    const [deviceTypes, setDeviceType] = useState([]);
    const [devicesLoaded, setDevicesLoaded] = useState(false);
    const [devices, setDevices] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const searchTextRef = useRef(null);
    const navigate = useNavigate();

    // Sorting functionality
    const [sorting, setSorting] = useState([
        {
            id: "name",
            desc: false,
        }
    ]);

    // Filtering functionality
    const [selectedDeviceType, setSelectedDeviceType] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(Status);

    // Device table setup
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            header: "Name",
        }),
        columnHelper.accessor("type", {
            header: "Device Type",
        }),
        columnHelper.accessor("inFlight", {
            header: "Status",
        }),
        columnHelper.accessor("pin", {
            header: "PIN",
        }),
        columnHelper.accessor("timestamp", {
            header: "Last updated",
        }),
    ];

    const table = useReactTable({
        data: devices,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
        },
        onSortingChange: setSorting,
    });

    const deviceTypeDropdown = () => {
        return <>
            <Dropdown className="dropdown-button">
                <Dropdown.Toggle>
                    Device Type <span className="dropdown-title">
                        {selectedDeviceType.length === deviceTypes.length || selectedDeviceType.length === 0
                            ? "All" : `(${selectedDeviceType.length})`}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="device-dropdown-menu">
                    {deviceTypes.map((value) => {
                        return (
                            <Form.Check reverse type="checkbox" id={value} className="item-label" key={value}>
                                <Form.Check.Input
                                    type="checkbox"
                                    isValid
                                    onChange={(e) => handleDeviceTypeSelect(e)}
                                    checked={selectedDeviceType.includes(value)} />
                                <Form.Check.Label>{value}</Form.Check.Label>
                            </Form.Check>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </>;
    }

    const handleStatusSelectEvent = (event) => {
        if (event !== undefined) {
            if (event.target.checked && !selectedStatus.includes(event.target.id)) {
                selectedStatus.push(event.target.id);
                setSelectedStatus([...selectedStatus]);
            } else {
                const filteredStatus = [...selectedStatus.filter(x => x !== event.target.id)];
                setSelectedStatus([...filteredStatus]);
            }
        }
    }

    const statusDropdown = () => {
        return <>
            <Dropdown className="dropdown-button">
                <Dropdown.Toggle variant="secondary">
                    Status  <span className="dropdown-title">
                        {selectedStatus.length === Status.length || selectedStatus.length === 0
                            ? "All" : `(${selectedStatus.length})`}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {Status.map((value) => {
                        return (
                            <Form.Check reverse type="checkbox" id={value} className="item-label" key={value}>
                                <Form.Check.Input
                                    type="checkbox"
                                    isValid
                                    onChange={(e) => { handleStatusSelectEvent(e) }}
                                    checked={selectedStatus.includes(value)} />
                                <Form.Check.Label>{value}</Form.Check.Label>
                            </Form.Check>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </>;
    }

    const handleDeviceTypeSelect = (event) => {
        if (event !== undefined) {
            if (event.target.checked && !selectedDeviceType.includes(event.target.id)) {
                selectedDeviceType.push(event.target.id);
                setSelectedDeviceType([...selectedDeviceType]);
            } else {
                setSelectedDeviceType([...selectedDeviceType.filter(x => x !== event.target.id)]);
            }
        }
    }
    const handleSortToggle = (header) => {
        const currentSort = header.column.getIsSorted();
        const nextSort =
            currentSort === 'asc' ? 'desc' :
                currentSort === 'desc' ? '' :
                    'asc';
        header.column.toggleSorting(nextSort);
    };

    useEffect(() => {
        // handle dropdown filters
        var filteredDevices = [...originalDevicesList];
        if (searchInput !== '') {
            filteredDevices = [...filteredDevices
                .filter(x => {
                    // Check if both x.name and x.pin are not null before calling toLowerCase()
                    if (x.name !== null && x.pin !== null) {
                        return x.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                            x.pin.toLowerCase().includes(searchInput.toLowerCase());
                    }
                    return false; // Return false if either x.name or x.pin is null
                })]
        }
        if (selectedDeviceType.length > 0) {
            filteredDevices = [...filteredDevices
                .filter(device => { return selectedDeviceType.includes(device.type.toLowerCase()) })];
        }
        if (selectedStatus.length > 0) {
            filteredDevices = [...filteredDevices
                .filter(device => { return selectedStatus.includes(!device.inFlight ? "ready" : "in flight") })];
        }

        setDevices(filteredDevices);
    }, [searchInput, selectedDeviceType, selectedStatus]);

    useEffect(() => {
        GetDevices().then((res) => {
            if (res !== undefined && res.data !== undefined) {
                setDevices(res.data);
                setDeviceType(GetDeviceType(res.data))
                setSelectedDeviceType(GetDeviceType(res.data))
                originalDevicesList = res.data;
            }
            setDevicesLoaded(true);
        })
        .catch((err) => {
            setDevicesLoaded(true);
            console.error(err);
        });
    }, []);

    if (!devicesLoaded) {
        return <DeviceListSkeleton />;
    }

    return (
        <div className="main-content">
            <p id='page-title'>Devices ({originalDevicesList.length})</p>
            {/* dropdown button filter */}
            <div className="filter">
                <div className="search-txt">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    <Form>
                        <input
                            type="text"
                            name="searchText"
                            placeholder="Search"
                            ref={searchTextRef}
                            onChange={(e) => setSearchInput(e.target.value)} />
                    </Form>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={`clear-icon ${searchInput === '' ? " hide" : ""}`}
                        onClick={() => {
                            setSearchInput('');
                            searchTextRef.current.value = '';
                        }}
                    />
                </div>
                {deviceTypeDropdown()}
                {statusDropdown()}
            </div>
            <div className="devices-container">
                <table className="device-table">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => {
                            return (
                                <tr className="header" key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <th scope="col" key={header.id} id={header.id}>
                                                <div onClick={() => handleSortToggle(header)}>
                                                    {!header.isPlaceholder &&
                                                        flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    {
                                                        header.column.getIsSorted() === 'asc' &&
                                                        <FontAwesomeIcon icon={faSortUp} className="sorting-icon" />
                                                    }
                                                    {
                                                        header.column.getIsSorted() === 'desc' &&
                                                        <FontAwesomeIcon icon={faSortDown} className="sorting-icon" />
                                                    }
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody>
                        {devices.length === 0 ?
                            <tr className="empty-row">
                                <td colSpan={5} >No devices match current filters</td>
                            </tr> :
                            (table.getRowModel().rows.map((row) => {
                                return (
                                    <tr key={row.id}  onClick={() => navigate(`/devices/${devices[row.id].id}`) }>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td key={cell.id} className={`device-details ${cell.id} ${cell.id.split('_')[1] === 'name' ? 'name-cell' : ''}`}>
                                                    {!cell.id.includes("timestamp") ? (
                                                        cell.id.includes("inFlight") ? (
                                                            DeviceStatus(cell.getValue())
                                                        ) : (
                                                            <div>
                                                                {cell.getValue() !== null ? 
                                                                <>
                                                                    {flexRender(cell.column.columnDef.cell, cell.getContext() ?? "N/A")}
                                                                </> : <p>N/A</p>}
                                                            </div>
                                                        )
                                                    ) : (
                                                        <p>{LastUpdatedText(cell.getValue())}</p>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DeviceList;