import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Row, Col } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css'
import "./TrackingInfoListSkeleton.css"

const TrackingInfoListSkeleton = () => {
  return (
    <section className="skeleton">
      <h2 className="section-title">
        <Skeleton duration={1} height={38} width={200}/>
      </h2>
        {Array(3)
          .fill()
          .map((item, index) => (
            <SkeletonTheme key={index}>
              <div className="skeleton-card">
                <Skeleton height={38}/>
                <h4 className="card-title">
                <div id="skeleton-scan-event">
                  <Skeleton height={25} className="mt-10"/>
                  <Skeleton height={25} className="p-20 m-20"/>
                  <Skeleton height={25} className="p-20 m-20"/>
                </div>

                <div id="skeleton-device-event">
                  <Skeleton height={20} className="p-20 m-20"/>
                  <Skeleton height={20} className="p-20 m-20"/>
                </div>
                </h4>
              </div>
            </SkeletonTheme>
          ))}
    </section>
  );
};

const TrackingContainerSkeleton = () => {
  return (
    <section className="skeleton">
      <Row>
        <Col>
            <Skeleton height={"100vh"}></Skeleton>
        </Col>
      </Row>
    </section>
  );
};

export { TrackingInfoListSkeleton, TrackingContainerSkeleton };