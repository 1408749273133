import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, InfoWindow, Polyline, Marker, MarkerClusterer } from '@react-google-maps/api';
import { GetMarkerIconByEvent } from "../components/CommonComponent";
import moment from "moment/moment";
import locationImg from '../assets/location.svg';
import destinationFlag from '../assets/destinationIcon.svg';
import currentLocation from '../assets/current_location.svg';
import { v4 as uuid } from 'uuid'
import "./GoogleMap.css";

// Generated custom google maps styles from https://mapstyle.withgoogle.com/
const styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#a5b076"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#acc2d9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]


const GoogleMapsContainer = ({
  latestEvent,
  showedPath,
  filters,
  deliveryAddress,
  deviceEvents,
  forcedRefresh
}) => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    const [coords, setCoords] = useState([]);
  
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: `${process.env.REACT_APP_VISTA_GOOGLE_MAPS_KEY}`,
    })

  const containerStyle = {
    height: `100vh`
  };

  const generateMarker = () => {
    setMarkers([]);
    const newMarkers = [];
    var events = [];
    if (latestEvent !== null && latestEvent.data !== null && latestEvent.data.latitude !== null) {
      events = [...deviceEvents.filter(e => e.data.id !== latestEvent.data.id)];
    } else {
      events = [...deviceEvents]
    }
    for (var i = 0; i < events.length; i++) {
      const data = events[i].data;
      if (data.latitude !== null && data.longitude !== null) {
        var icon = "";
        if (showedPath && (filters === null || filters.length === 0)) {
          icon = locationImg;
        } else {
          icon = GetMarkerIconByEvent(data, filters);
        }
        if (newMarkers.findIndex(m => m.id === data.id) === -1) {
          newMarkers.push({
            icon: icon,
            id: data.id,
            dateTime: events[i].eventDateTime,
            position: {lat: data.latitude, lng: data.longitude},
            data: {
              passThreshold: data.passThreshold,
              battery: data.battery,
              gForce: data.gForce,
              temperature: data.temperature,
              lightLevel: data.lightLevel
            }
          })
        }
      }
    }

    if (newMarkers.length > 0) {
      setCoords(newMarkers.map(i => {return {lat: i.position.lat, lng: i.position.lng}}));
    } else {
      setCoords([]);
    }

    if (deliveryAddress !== null && deliveryAddress.latitude !== null && deliveryAddress.longitude !== null) {
      const destinationMarker = {
        id: uuid(),
        icon: destinationFlag,
        status: "destination",
        description: deliveryAddress?.address,
        position: { 
          lat: deliveryAddress.latitude, 
          lng: deliveryAddress.longitude
        }
      };
      newMarkers.push(destinationMarker);
    }

    if (latestEvent !== null && latestEvent.data !== null && 
      latestEvent.data.latitude !== undefined && latestEvent.data.latitude !== null) {
      const latestLocation = {
        icon: currentLocation,
        id: latestEvent.data.id,
          dateTime: latestEvent.eventDateTime,
          position: {lat: latestEvent.data.latitude, lng: latestEvent.data.longitude},
          data: {
            battery: latestEvent.data.battery,
            gForce: latestEvent.data.gForce,
            temperature: latestEvent.data.temperature,
            lightLevel: latestEvent.data.lightLevel
          }
      };
      newMarkers.push(latestLocation);
    }

    setMarkers([...newMarkers]);
    if (map !== null && forcedRefresh) {
      var bounds = new window.google.maps.LatLngBounds();
      newMarkers.forEach(({ position }) => bounds.extend(position));
      map.fitBounds(bounds);
      setMap(map)
    }
  }

    useEffect(() => {
      generateMarker(forcedRefresh);
    }, [deviceEvents, forcedRefresh]);

    const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };

    const onLoad = useCallback(function callback(map) {
      setMarkers(markers.sort(function(a,b) {
        return new Date(a.eventDateTime) - new Date(b.eventDateTime);
      }));
      
      var bounds = new window.google.maps.LatLngBounds();
      markers.forEach(({ position }) => bounds.extend(position));
      map.fitBounds(bounds);
      setMap(map)
    }, [markers])
  
  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

    return isLoaded &&
      <GoogleMap
          setMap={setMap}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={containerStyle}
          zoom={8}
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerClassName='google-maps-container'
          options={{
            styles,
            maxZoom: 18,
            fullscreenControl: false,
            streetViewControl: false,
            keyboardShortcuts: false,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.LEFT_BOTTOM,
            },
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_CENTER,
            }
          }}>
            {coords.map(({_}, index) => (       
              <Polyline
                key={index}
                path={[index !== 0 ? coords[index - 1] : coords[index], coords[index]]}
                options={{
                  strokeColor: "#4D97D2",
                  strokeOpacity: 1,
                  strokeWeight: 1,
                  icons: [
                    {
                      icon: {
                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        fillColor: "#4D97D2",
                        strokeWeight: 2,
                      },
                      offset:"100%",
                    },
                  ]
              }} />
            ))}

            {/* All markers */}
            <MarkerClusterer
              maxZoom={15}
              minimumClusterSize={3}
              averageCenter
              enableRetinaIcons
              gridSize={20}
              clusterClass="custom-marker-cluster"
              onClick={(cluster) => {
                //TODO: Decide if we need to show the InfoWindow, and which information needs to be displayed
              }}
            >
              {(clusterer) => 
                markers.map(({position, id, status, description, dateTime, data, icon}, index) => (       
                  <Marker
                    clusterer={clusterer}
                    zIndex={index}
                    key={id}
                    position={position}
                    onClick={() => handleActiveMarker(id)}
                    icon={icon}
                    >
                    {activeMarker === id &&
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                          {status !== "destination" ?
                            <div className="info-window">
                              <p id="datetime">{moment(dateTime).format('MMM DD h:mm A')}</p>
                              <p id="status">{status}</p>
                              <p className="description">{description}</p>
                              {data &&
                                <>
                                  {<p className="description">ID: {id}</p>}
                                  {<p className="description">{position.lat}, {position.lng}</p>}
                                  {data.passThreshold && 
                                    <>
                                      {data.battery || data.battery === 0 ? <p className="description">Battery: {data.battery}%</p> : <></>}
                                      {data.gForce ? <p className="description">G-Force: {data.gForce}</p> : <></>}
                                      {data.temperature || data.temperature === 0 ?<p className="description">Temperature: {data.temperature} °C</p> : <></>}
                                      {data.lightLevel ? <p className="description">Light Level: {data.lightLevel}</p> : <></>}
                                    </>
                                  }
                                </>
                              }
                            </div> :
                            <div className="info-window">
                              <p id="status">Delivery Address</p>
                              <p className="description">{description}</p>
                            </div>
                        }
                      </InfoWindow>
                    }
                  </Marker>
              ))}
            </MarkerClusterer>
      </GoogleMap>
  }

  export { GoogleMapsContainer }

