import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css'
import "./DeviceListSkeleton.css"

const DeviceListSkeleton = () => {
    return (
        <div className="main-content">
        <section className="device-list-skeleton">
            <Col>
                <Skeleton duration={1} height={38} width={250}/>
                <div className="devices-control-skeleton">
                    <div><Skeleton duration={1} height={38} width={300}/></div>
                    <div><Skeleton duration={1} height={38} width={200}/></div>
                    <div><Skeleton duration={1} height={38} width={200}/></div>
                </div>
                <Row>
                    <table className="device-table">
                        <thead>
                            <tr>
                                {Array(5)
                                .fill()
                                    .map((item, index) => (
                                        <th key={index}>
                                            <Skeleton duration={1} height={38}/> 
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array(10)
                            .fill()
                                .map((item, index) => (
                                    <tr key={index}>
                                        {Array(5)
                                            .fill()
                                            .map((item, i) => (
                                                <td key={i}>
                                                    <Skeleton duration={1} height={30}/> 
                                                </td>
                                            ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </Row>
            </Col>
        </section>
      </div>
    );
  };

  export default DeviceListSkeleton;