import React from "react";
import { useMsal } from "@azure/msal-react";
import { Image, Row, Col } from 'react-bootstrap';
import { loginRequest } from '../Auth/authConfig';
import vistaLogo from '../assets/vista_dark.png';
import purolatorLogo from '../assets/purolator-digital-lab.png';
import microsoft from '../assets/microsoft.png'
import '../Login/Login.css'

export const Login = () => {
    const { instance } = useMsal();

    const handleRedirect = () => {
        instance
          .loginRedirect({
            ...loginRequest,
            redirectStartPage: "/devices",
          })
          .catch((error) => console.log(error));
    };
    return (
        <div className="login-container">
            <div className="login-main-container">
                <Row id='img-container'>
                    <Image fluid src={vistaLogo}></Image>
                </Row>
                <Row id='login-btn-container' onClick={handleRedirect}>
                    <Col id="microsoft-icon">
                        <Image fluid src={microsoft}></Image>
                    </Col>
                    <Col>
                        <p>Sign in with Microsoft</p>
                    </Col>
                </Row>
                <Row id='login-footer-container'>
                    <p>Powered by <Image fluid src={purolatorLogo}></Image></p>
                </Row>
            </div>
        </div>
    );
}