import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMobileScreen, faHouse } from '@fortawesome/free-solid-svg-icons'
import { PageFooter } from './PageFooter';
import { Outlet, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import { Col, Image } from "react-bootstrap";
import { Avatar } from './Avatar';
import { useLocation } from 'react-router-dom';
import vistaLogo from '../assets/vista.png';
import vistaIcon from '../assets/vista_icon.png';
import './LandingPage.css';

export const LandingPage = () => {
    const location = useLocation();
    const { pathname } = location;
    const [smallNavCollapse, setSmallNavCollapse] = useState(false);
    const navigate = useNavigate();
    const ref = useRef(null);
    const environment = process.env.REACT_APP_ENV;
    const [hidden, setHidden] = useState(true);

    // Collapse sidemenu when clicking outside in mobile view
    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target) && event.target.className !== 'user-name' ) {
          if (!smallNavCollapse) {
            setSmallNavCollapse(false);
          }
        }
    };

    // Collapse sidemenu when scrolling in mobile view
    const handleScroll = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (!smallNavCollapse) {
            setSmallNavCollapse(false);
          }
        }
    };
      
    useEffect(() => {
        document.addEventListener("scroll", handleScroll, true);
        document.addEventListener("click", handleClickOutside, true);
    }, []);
    
    return (
      <div className="main-container">
        <div
          className={`sidebar-content ${hidden ? " sidebar-isCollapsed" : ""}`}
          onMouseEnter={() => setHidden(false)}
          onMouseLeave={() => setHidden(true)}
        >
          <div className={`nav ${smallNavCollapse ? " isCollapsed" : ""}`}>
            <div className="logo">
              <Image fluid src={hidden ? vistaIcon : vistaLogo}></Image>
              <FontAwesomeIcon
                className="small-device-icon"
                icon={faBars}
                onClick={() => setSmallNavCollapse(!smallNavCollapse)}
                ref={ref}
              />
            </div>
          </div>
          <div
            className={`${
              smallNavCollapse ? " smallNav " : ""} sidebar-container ${
              environment === 'dev' ? 'dev-env' : (environment === 'qa' ? 'qa-env' : '')} ${
              hidden ? "isCollapsed" : ""}`}
            >
              <div
                className={`nav-option option ${
                  pathname.includes("/devices") ||  pathname.includes("/") ? " selected" : ""
                } `}
                onClick={() => navigate(`/devices`)}
              >
                <FontAwesomeIcon
                  icon={faMobileScreen}
                  className="device-icon"
                />
                <p>{hidden ? "" : "Devices"}</p>
              </div>
              <div className="nav-option option">
                <Avatar hasUserName={!hidden}/>
              </div>
            </div>
          </div>
        <Col className="page-content">
          {pathname === "/login" ? <Navigate to="/" /> : <Outlet />}
          <PageFooter />
        </Col>
      </div>
    );
}