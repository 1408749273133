export const msalConfig = {
    auth: {
        authority: process.env.REACT_APP_AAD_AUTHORITY + process.env.REACT_APP_AAD_TENANT_ID,
        clientId: process.env.REACT_APP_AAD_CLIENT_ID,
        redirectUri: window.location.origin + '/auth'
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

export const loginRequest = {
    scopes: [
        'user.read'
    ]
};
