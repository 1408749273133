import React, { useState } from 'react';
import './ToggleSlider.css';

const ToggleSlider = ({
    checked,
    callback
}) => {
  const handleToggle = () => {
    callback(!checked)
  };

  return (
    <div className="toggle-slider">
      <input
        type="checkbox"
        id="toggle"
        checked={checked}
        onChange={handleToggle}
      />
      <label htmlFor="toggle" className="slider" />
    </div>
  );
};

export default ToggleSlider;