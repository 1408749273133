import axios from 'axios';

/** Specify config defaults that will be applied to every request. */
const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_VISTA_API_URL}`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
        "x-functions-key": process.env.REACT_APP_VISTA_API_KEY,
   },
});

export default axiosInstance;