import React, { useState, useEffect, useRef } from 'react'
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { GetDeviceTrackingData, GetDeviceDetailsByDeviceId } from '../api/devices';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { DeviceStatus, NoDataFoundContainer, CLIENT_ID } from "../components/CommonComponent"
import { TrackingInfoMapView } from './TrackingInfoMapView';
import { TrackingContainerSkeleton } from "./TrackingInfoListSkeleton";
import { ManageDeviceSidePanel } from './ManageDeviceSidePanel';
import "./DeviceDetails.css";

// 'enum' event type
export const EventType = Object.freeze({
  Battery: 1,
  GForce: 2,
  LowTemp: 3,
  HighTemp: 4, 
  LightLevel: 5
});

export const DeviceDetails = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const clientId = sessionStorage.getItem("clientId");
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [trackingDataLoaded, setTrackingDataLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [originalDeviceEvents, setOriginalDeviceEvents] = useState([]);
  const [deliveryAddress, setDeliveryAddress]= useState(null);
  const [shipments, setShipments] = useState([]);
  const [puroTrackingInfo, setPuroTrackingInfo] = useState([]);
  const [sidePanelCollapsed, setSidePanelCollapsed] = useState(true);

  const removeDuplicateEvent = (deviceEvents) => {
    var result = deviceEvents.filter(x => 
      x.data.latitude !== null && 
      x.data.latitude !== undefined && 
      x.data.longitude !== undefined && 
      x.data.longitude !== null);
    result = result.reduce((unique, o) => {
        if(!unique.some(obj => 
          obj.data.battery === o.data.battery && 
          obj.data.gForce === o.data.gForce && 
          obj.data.temperature === o.data.temperature &&
          obj.data.lightLevel === o.data.lightLevel &&
          obj.data.latitude === o.data.latitude &&
          obj.data.longitude === o.data.longitude)) {
          unique.push(o);
        }
        return unique;
    },[]);
    result = [...result.sort(function(a,b){
      return new Date(a.eventDateTime) - new Date(b.eventDateTime);
    })];
    return result;
  }

  const removeDuplicateTrackingScan = (trackingScans) => {
    var result = trackingScans.reduce((unique, o) => {
        if(!unique.some(obj => 
          obj.data.scanType === o.data.scanType && 
          obj.data.description === o.data.description && 
          obj.data.deliveryAddress === o.data.deliveryAddress &&
          obj.data.depot === o.data.depot)) {
          unique.push(o);
        }
        return unique;
    },[]);
    return result;
  }

  const getDeliveryAddress = (shipment) => {
    var formattedAddress = "";
    if (shipment !== null && shipment !== undefined) {
        if (shipment.addressLine2 !== null && shipment.addressLine2 !== '') {
            formattedAddress += `${shipment.addressLine2} - `;
        }
        if (shipment.addressLine1 !== null && shipment.addressLine1 !== '') {
            formattedAddress += `${shipment.addressLine1}, `;
        }
        if (shipment.city !== null && shipment.city !== '') {
            formattedAddress += shipment.city;
        }
        if (shipment.postalCode !== null && shipment.postalCode !== '') {
            formattedAddress += ` ${shipment.postalCode}`;
        }
        setDeliveryAddress({
            address: formattedAddress,
            latitude: shipment.latitude,
            longitude: shipment.longitude
        })
    }
}

  const getTrackingData = async (shipment) => {
    if (shipment !== null && shipment !== undefined) {
      getDeliveryAddress(shipment);
      GetDeviceTrackingData(clientId ?? CLIENT_ID, deviceId, shipment.id).then(res => {
        if (res !== undefined) {
          if (res.trackingData) {
            setPuroTrackingInfo([...removeDuplicateTrackingScan(res.trackingData.filter(x => x.source === 0))]);
            const originEvents = ([...removeDuplicateEvent(res.trackingData.filter(x => x.source === 1))]);
            setOriginalDeviceEvents([...originEvents]);
          }
          setTrackingDataLoaded(true);
        } else {
          setPuroTrackingInfo([]);
          setOriginalDeviceEvents([]);
          setTrackingDataLoaded(true);
        }
      })
      .catch((err) => {
        setPuroTrackingInfo([]);
        setOriginalDeviceEvents([]);
        setTrackingDataLoaded(true);
        console.error(err);
      });
    }
  }

  const getFinalTrackingData = async (shipment) => {
    if (shipment !== null) {
      await getTrackingData(shipment);
    }
  }
  
  useEffect(() => {
    if (deviceId !== "") {
      GetDeviceDetailsByDeviceId(deviceId).then((res) => {
        if (res !== undefined && res.data !== undefined) {
            setDeviceDetails(res.data);
            if (res.data !== null && res.data.shipments !== null && res.data.shipments.length > 0) {
              var sortedShipments = sortShipmentsByTimestamp(res.data.shipments);
              setShipments([...sortedShipments])
              getFinalTrackingData(sortedShipments.length > 0 ? sortedShipments[0] : null);
            } else {
              setTrackingDataLoaded(true);
            }
            setDataLoaded(true);
          } else {
            setError(true);
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          setDataLoaded(true);
          setError(true);
          console.error(err);
        });
      }
      else {
        setDataLoaded(true);
        setError(true);
      }
  }, []);

  const sortShipmentsByTimestamp = (originShipments) => {
    var shipments = originShipments;
    if (shipments !== undefined && shipments != null && shipments.length > 0) {
      shipments = shipments.sort(function(a,b){
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
    }
    return shipments ?? [];
  }

  return (
    <div className="main-content">
      <div className='top-container'>
        <div className='d-flex justify-content-between h-50'>
          <p onClick={() => navigate(`/devices`, { replace: true })} className='back-button'><FontAwesomeIcon icon={faArrowLeft}/>Back to Devices</p>
          <div onClick={() => setSidePanelCollapsed(!sidePanelCollapsed)} className='manage-device-col'>
            <span>Manage Device</span>
          </div>
        </div>
        {dataLoaded && !error ? 
          <Col className='d-flex m-20'>
            <p id="device-name">{deviceDetails.name}</p>
            <div>{DeviceStatus(deviceDetails.inFlight)}</div>
          </Col> : (!error ? <Skeleton duration={1} height={32} width={300}/> : <></>)
          }
      </div>
      <div className='device-info-container'>
        {error ? <NoDataFoundContainer/> : 
        <>
          {trackingDataLoaded ?
              <TrackingInfoMapView
                puroTrackingInfo={puroTrackingInfo}
                deviceEvents={originalDeviceEvents}
                deliveryAddress={deliveryAddress}
                shipments={shipments}
                callback={(val) => {
                  if (val !== null) {
                    setOriginalDeviceEvents([]);
                    setDeliveryAddress(null);
                    getTrackingData(val);
                  }
                }}
          /> : <TrackingContainerSkeleton/>
        }
        {!sidePanelCollapsed && 
          <div className='device-side-panel' ref={ref}>
            <ManageDeviceSidePanel
              deviceDetails={deviceDetails}
              callback={() => {
                setSidePanelCollapsed(true);
              }}
            />
          </div>}
        </>}
      </div>
    </div>
  );
}