import React from 'react'
import { Tooltip } from 'react-tooltip'
import { useNavigate } from "react-router-dom"
import { useMsal } from '@azure/msal-react';

export const Avatar = ({hasUserName}) => {
    const navigate = useNavigate()
    const { instance, accounts } = useMsal();
    const name = accounts !== undefined && accounts[0] !== undefined ? accounts[0].name : "";

    const handleLogoutRedirect = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: '/',
            onRedirectNavigate: (url) => {
                // Return false if you would like to stop navigation after local logout
                return false;
            }
        }).catch((error) => console.log(error));
        navigate("/", { replace: true });
    };

    const getAvatarName = () => {
        if (name !== "") {
            var nameShortForm = name.match(/\b(\w)/g);
            if (nameShortForm !== null) {
                nameShortForm = nameShortForm.join('');
                return nameShortForm.slice(0,2);
            }
        }
    }
    
    return (
        <div className={`avatar-container ${!hasUserName ? "isCollapsed" : ""}`} id="clickable">
            <div id="avatar-logo">
                <p>{getAvatarName() ?? "SK"}</p>
            </div>
            {hasUserName &&
                <>
                    <p className='user-name'>{name.match(/[\w\d,.\s]+/, '')}</p>
                    <Tooltip anchorSelect="#clickable" clickable className='avatar-tooltip' style={{background: "var(--white)", borderRadius: "8px"}}>
                        <button className="logout-btn" onClick={handleLogoutRedirect}>Log out</button>
                    </Tooltip>
                </>
            }
        </div>
    );
}