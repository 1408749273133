import React from 'react'
import purolatorLogo from '../assets/purolator-digital-lab.png';
import { Image} from "react-bootstrap";

export const PageFooter = () => {
    return (
        <div className='footer z-3'>
            <p>Powered by <Image fluid src={purolatorLogo}></Image></p>
        </div>
    );
}