import { Row, Col } from 'react-bootstrap';
import moment from "moment/moment";
import { ShippingLabelViewer } from './ShippingLabelViewer';
import "./ManageDeviceSidePanel.css"

export const ManageDeviceSidePanel = ({
    deviceDetails,
    callback
}) => {
    
    return <>
        <div className='top-side-panel-container z-1'>
            <span id="manage-device-title">Manage Device</span>
            <div className="manage-device-close-btn" onClick={() => callback()}>
                <span>Close</span>
            </div>
        </div>
        <p className='manage-device-section'>Device Information</p>
            <Row className='m-0 p-0'>
                <Col id="device-info-table">
                    <Row id="info-table-border-top">
                        <Col className='device-details-label'><p>Device name</p></Col>
                        <Col className='device-details-value'><p>{deviceDetails.name ?? "N/A"}</p></Col>
                    </Row>
                    <Row>
                        <Col className='device-details-label'><p>Device IMEI</p></Col>
                        <Col className='device-details-value'><p>{deviceDetails.settings.imei ?? "N/A"}</p></Col>
                    </Row>
                    <Row>
                        <Col className='device-details-label'><p>Last time checked in</p> </Col>
                        <Col className='device-details-value'><p>{deviceDetails.settings !== null && deviceDetails.settings.lastConnected !== null ? 
                            moment.utc(deviceDetails.settings.lastConnected).local().format('YYYY-MM-DD h:mm A') : "N/A"}</p></Col>
                    </Row>
                    <Row>
                        <Col className='device-details-label'><p>Update frequency</p></Col>
                        {deviceDetails !== null && deviceDetails.settings.updateFrequencyInSeconds !== null ? 
                            <Col className='device-details-value'>{deviceDetails.settings.updateFrequencyInSeconds} seconds ({Math.floor(deviceDetails.settings.updateFrequencyInSeconds / 60)} minutes)</Col>:
                            <Col className='device-details-value'>N/A</Col>}
                    </Row>
                    <Row id="info-table-border-bottom">
                        <Col className='device-details-label'><p>Battery level</p></Col>
                        <Col className='device-details-value'><p>{deviceDetails.battery ? deviceDetails.battery + "%": "N/A"}</p> </Col>
                    </Row>
                </Col>
            </Row>
        <p className='manage-device-section'>Label Information</p>
        <ShippingLabelViewer
            currentLabelBase64={deviceDetails.settings.currentLabelBase64}
            pendingLabelBase64={deviceDetails.settings.pendingLabelBase64}
            pendingLabelETA={deviceDetails.settings.pendingLabelETA}
            clientId={deviceDetails.clientId}
            deviceId={deviceDetails.id}
            updateFrequencyInSeconds={deviceDetails.settings.updateFrequencyInSeconds}
        />
    </>
}