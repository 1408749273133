import React, { useState, useEffect } from 'react'
import { Slide, ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { Document, pdfjs, Page } from 'react-pdf';
import { UploadLabel } from '../api/devices';
import { LoadingIcon } from "../components/Loading";
import { Col, Button, Row, Image, Container } from 'react-bootstrap';
import sampleLabel from '../assets/sample_label.png';
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import "./ShippingLabelViewer.css"
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

// Need for rendering PDF file in browsers
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const ShippingLabelViewer = ({
    currentLabelBase64,
    pendingLabelBase64,
    pendingLabelETA,
    clientId,
    deviceId,
    updateFrequencyInSeconds
}) => {
    const [pendingBase64, setPendingBase64] = useState("");
    const [currentBase64, setCurrentBase64] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");
    const [pendingFile, setPendingFile] = useState(null);
    const [pendingETA, setPendingETA] = useState(null);
    const [confirmedLabel, setConfirmedLabel] = useState(false);
    const [uploadingLabel, setUploadingLabel] = useState(false);
    const [uploadedLabelError, setUploadedLabelError] = useState(false);

    const getFileShortName = (fileName) => {
        if (fileName !== '') {
            var shortName = fileName.substring(fileName.indexOf('/') + 1);
            shortName = shortName !== undefined ? shortName : fileName;
            return shortName.substring(shortName.length - 15);
        }
    }

    const getBase64FileMimeTypeParts = (base64File) => {
        
        var mimetype = undefined;

        if(base64File !== null && base64File !== undefined){
            // decode the base 64 file
            var decoded = atob(base64File);       

            // take the decoded base 64 and convert it all to lowercase
            var lowerCase = decoded.toLowerCase();

            // figure out which of the mimetypes we need to create
            if (lowerCase.indexOf("png") !== -1) mimetype = ["image", "png"];
            else if (lowerCase.indexOf("gif") !== -1) mimetype = ["image", "gif"];
            else if (lowerCase.indexOf("bmp") !== -1) mimetype = ["image", "bmp"];
            else if (lowerCase.indexOf("jpg") !== -1) mimetype = ["image", "jpg"];
            else if  (lowerCase.indexOf("jpeg") !== -1)mimetype = ["image", "jpeg"];
            else if (lowerCase.indexOf("pdf") !== -1) mimetype = ["application", "pdf"];
            console.log(mimetype);
        }

        return mimetype;
    }

    useEffect(() => {
        var type, ext;
        
        if(pendingBase64)[type, ext] = getBase64FileMimeTypeParts(pendingLabelBase64);
        setPendingBase64(pendingLabelBase64 ? `data:${type}/${ext};base64,${pendingLabelBase64}` : "");
        
        if(currentLabelBase64)[type, ext] = getBase64FileMimeTypeParts(currentLabelBase64);
        setCurrentBase64(currentLabelBase64 ? `data:${type}/${ext};base64,${currentLabelBase64}` : "");
        
        setPendingETA(pendingLabelETA);
        setConfirmedLabel(currentLabelBase64 !== '' || pendingLabelBase64 !== '');
    }, []);

    const changeLabel = () => {
        setSelectedFileName("");
        setConfirmedLabel(false);
    }

    const confirmLabel = () => {
        setUploadingLabel(true);
        UploadLabel(clientId, deviceId, pendingFile).then((res) => {
            if (res !== undefined && res.status === 202) {
                toast.success('Your shipment has been received. Label will be uploaded on the device next update');
                setUploadedLabelError(true)
                setConfirmedLabel(true);

            } else {
                toast.error('Error: Please,Try again.');
                setUploadedLabelError(true)
            }
            setUploadingLabel(false);
        })
        .catch((err) => {
            setUploadingLabel(false);
            setUploadedLabelError(true)
            console.error(err);
        });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setPendingFile(file);
        setSelectedFileName(file.name);
        setConfirmedLabel(false);
    };

    const labelViewer = (isPending) => {
        return <div className={`dropzone ${isPending & pendingBase64 === '' || !isPending & currentBase64 === '' ? "empty" : ""}`}>
            {isPending & pendingBase64 === "" || !isPending & currentBase64 === "" ?
                <>
                    <div className="position-relative">
                        <div className="empty-label-container">
                            <p className='label-desc'>No {isPending ? "pending" : "current"} label</p>
                        </div>
                        <Image id="empty-label-img" fluid src={sampleLabel}></Image>
                    </div>
                </> :
                <>
                {getBase64FileMimeTypeParts(isPending ? pendingLabelBase64 : currentLabelBase64)[1] === "pdf" ?
                    <section className='preview-label'>
                        {isPending && <div className="empty-label-container pending"></div>}
                            <Document file={isPending ? pendingBase64 : currentBase64}>
                                <Page pageNumber={1} ></Page>
                            </Document>
                        {isPending && pendingETA !== null ?
                            <div id="pending-label-eta">
                                <p id="title">Pending</p>
                                <p id="subtitle">Label will be applied by {moment.utc(pendingETA).local().format('YYYY-MM-DD h:mm A')}.<br /> Label upload will be verified by {moment.utc(pendingETA).add(updateFrequencyInSeconds, 's').add(5, 'm').local().format('YYYY-MM-DD h:mm A')}. </p>
                            </div> : <></>
                        }
                    </section>
                    : <section className='preview-label-img'>
                        {isPending && <div className="empty-label-container"></div>}                        
                            <Image src={isPending ? pendingBase64 : currentBase64} fluid></Image>
                        {isPending && pendingETA !== null ?
                            <div id="pending-label-eta-image">
                                <p id="title">Pending</p>
                                <p id="subtitle">Label will be applied by {moment.utc(pendingETA).local().format('YYYY-MM-DD h:mm A')}.<br /> Label upload will be verified by {moment.utc(pendingETA).add(updateFrequencyInSeconds, 's').add(5, 'm').local().format('YYYY-MM-DD h:mm A')}. </p>
                            </div> : <></>
                        }
                    </section>
                }
                </>
            }
        </div>
    }

    return <>
        <Container>
            <div className='label-viewer d-flex z-2'>
                {/* Current label */}
                <div className= {`current-label-container ${currentBase64 === '' ? "empty" : ""}`}>
                    {labelViewer(false)}
                    <span>Current</span>
                </div>
                {/* Pending label */}
                <div className={`pending-label-container ${pendingBase64 === '' ? "empty" : ""}`}>
                    {labelViewer(true)}
                    <span>Pending</span>
                </div>
            </div>
        </Container>
        <div className='upload-label-container'>
            <p className='label-title'>Upload Label</p>
            <p id="label-format">Accepted file formats: .bmp .gif .jpeg .jpg .pdf .png  </p>
                <p id='label-file'>Label File</p>
                {(!confirmedLabel && selectedFileName !== '') &&
                    <div className='confirm-file-name'>
                        <FontAwesomeIcon icon={faFile} />
                        {` ...${getFileShortName(selectedFileName)}`}
                        <FontAwesomeIcon id="check-mark-icon" icon={faCheck} />
                    </div>
                }
                <Row className='align-items-center'>
                    <Col xs={6}>
                        {(!confirmedLabel && selectedFileName !== '') ?
                            <Button
                                className='change-file-button'
                                onClick={changeLabel}
                                disabled={uploadingLabel}>
                                Change file
                            </Button> : 
                            <div className='d-flex'>
                                <input id="inputField" type="file" accept=".pdf,.bmp,.jpg,.jpeg,.gif,.png" onChange={handleFileChange} className='d-none' />
                                <label htmlFor="inputField" className="label-upload-btn">
                                    <FontAwesomeIcon icon={faArrowUpFromBracket} />Choose file
                                </label>
                            </div>
                        }
                    </Col>
                    <Col xs={6}>
                        <Button
                            className={`confirm-button ${selectedFileName === '' ? "disable" : ""}`}
                            disabled={selectedFileName === ''}
                            onClick={confirmLabel}
                        >
                            {uploadingLabel === true ? <LoadingIcon /> : "Confirm"}
                        </Button>
                    </Col>
                </Row>
                <ToastContainer
                    className={"label-toast-container"}
                    position="top-right"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    theme="dark"
                    transition={Slide}
                />
        </div>
    </>
}