import React from 'react'
import "./Loading.css"

const Loading = () => {
    return (
        <div className="main-content">
           <LoadingIcon/>
        </div>
    );
}

const LoadingIcon = () => {
    return (
        <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
        </div>
    );
}

export { Loading, LoadingIcon }