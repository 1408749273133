import axiosInstance from './baseApi';

const Status = [
    'ready',
    'in flight'
];

const GetDeviceType = (devices) => {
    var types = [];
    if (devices !== undefined && devices.length > 0) {
        types = Array.from(new Set(devices.map(ele=>ele.type.toLowerCase())));
    }
    return types;
}

class Client {
    constructor(client) {
        if (client !== null) {
            const { id, name, devices } = client;
            this.id = id;
            this.name = name;
            this.devices = devices;
          }
    }
}

class Device {
    constructor(device) {
        if (device !== null) {
          const { id, deviceType, deviceName, inFlight, lastEventDateTime, clientId, clientName } = device;
          this.id = id;
          this.type = deviceType;
          this.name = deviceName;
          this.inFlight = inFlight;
          this.lastEventDateTime = lastEventDateTime;
          this.clientId =  clientId;
          this.clientName = clientName;
        }
    }
}

class DeviceData {
    constructor(data) {
        if (data !== null) {
          const { latitude, longitude, bumps, battery, temperature, label } = data;
          this.latitude = latitude;
          this.longitude = longitude;
          this.bumps = bumps;
          this.battery = battery;
          this.temperature =  temperature;
          this.label = label;
        }
    }
}

/**
 * Get all devices
 */

const GetDevices = async () => {
    try {
        const response = await axiosInstance.get(`/api/devices`);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

/**
 * Get device details by client and device id
 * @param {string} deviceId
 */

const GetDeviceDetailsByDeviceId = async (deviceId) => { 
    try {
        const response = await axiosInstance.get(`/api/devices/${deviceId}`);
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

/**
 * Upload label by client and device id
 * @param {string} deviceId
 * @param {string} clientId
 * @param {File} label
 */

const UploadLabel = async (clientId, deviceId, label) => {
    try {
        const response = await axiosInstance.postForm(`/api/shipments`, {
            clientId: clientId,
            deviceId: deviceId,
            label: label
        });
        return response;
    }
    catch (error) {
        console.error(error);
    }
}

/**
 * Get device tracking data
 * @param {string} deviceId
 * @param {string} clientId
 * @param {string} shipmentId
 */

const GetDeviceTrackingData = async (clientId, deviceId, shipmentId) => {
    try {
        var targetUrl = `/api/clients/${clientId}/devices/${deviceId}/tracking?id=${shipmentId}`;
        const response =  await axiosInstance.get(targetUrl);
        return (response.data);
    }
    catch (error) {
        console.error(error);
    }
}

export { Client, Device, DeviceData, GetDevices, GetDeviceDetailsByDeviceId, Status, GetDeviceType, UploadLabel, GetDeviceTrackingData}