import React, { useEffect, useState, useRef } from 'react'
import { Col, Dropdown, Image, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp, faAngleLeft, faAngleRight, faPlus, faMinus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { NoDataFoundContainer, FilteringOptionInfo } from "../components/CommonComponent"
import moment from "moment/moment";
import { GoogleMapsContainer } from '../components/GoogleMap';
import { useSearchParams } from 'react-router-dom';
import ToggleSlider from '../components/ToggleSlider';
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";
import gForceImg from '../assets/gForced-legend.svg';
import batteryLevelImg from '../assets/batteryLevel.svg';
import lightLevelImg from '../assets/opened-legend.svg';
import tempColderImg from '../assets/temp-colder-legend.svg';
import tempWarmerImg from '../assets/temp-warmer-legend.svg';
import destinationFlag from '../assets/destinationIcon.svg';
import currentLocation from '../assets/current_location.svg';
import calendarIcon from '../assets/calendar-icon.svg';
import "./TrackingInfoMapView.css";
import { EventType } from './DeviceDetails';

const BatteryMin = 15;
const TempArbitrary = 7;
const BatteryArbitrary = 2;
const GForceArbitrary = 30;
const LightLevelArbitrary = 100;

const TrackingInfoMapView = ({
    puroTrackingInfo,
    deviceEvents,
    deliveryAddress,
    shipments,
    callback
}) => {
    const datePickerRef = useRef()
    const [currentQueryParameters, setSearchParams] = useSearchParams();
	const queryParameters = new URLSearchParams();
    const [allSanitizedEvents, setAllSanitizedEvents] = useState([]);
    const [filteredDeviceEvents, setFilteredDeviceEvents] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isShowedPath, setIsShowedPath] = useState(false);
    const [forcedRefresh, setForcedRefresh] = useState(true);
    const [timePickerCollapsed, setTimePickerCollapsed] = useState(true);
    const refScrollUp = useRef(null);
    const refScrollDown = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [dateRange, setDateRange] = useState([]);
    const hasTrackingInfo = puroTrackingInfo !== null && puroTrackingInfo !== undefined && puroTrackingInfo.length > 0;
    
    const [eventTypes, setEventTypes] = useState([
        {
            icon: batteryLevelImg,
            desc: "Battery low",
            id: EventType.Battery,
            checked: false
        },
        {
            icon: gForceImg,
            desc: "Impact",
            id: EventType.GForce,
            checked: false
        },
        {
            icon: tempColderImg,
            desc: "Low Temp",
            id: EventType.LowTemp,
            checked: false
        },
        {
            icon: tempWarmerImg,
            desc: "High Temp",
            id: EventType.HighTemp,
            checked: false
        },
        {
            icon: lightLevelImg,
            desc: "Opened",
            id: EventType.LightLevel,
            checked: false
        },
        {
            icon: destinationFlag,
            desc: "Destination",
            id: 6,
            checked: true,
            disabled: true,
        },
        {
            icon: currentLocation,
            desc: "Latest Location",
            id: 7,
            checked: true,
            disabled: true,
        },
    ]);

    const handleScrollUp = () => {
        refScrollUp.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest"
        });
    };

    const getSanitizedDeviceEvents = (events) => {
        var originEvents = events.map(x => { return {...x}});
        // capture only different device details from events

        var prevBattery = null;
        var prevGForce = null;
        var prevLightLevel = null;
        var preTemp = null;
        var avgTemp = 0;
        
        for (var i = 0; i < events.length; i++) {
            let battery = null;
            let gForce = null;
            let temp = null;
            let lightLevel = null;
            const event = events[i].data;
            
            const newDeviceEvents = originEvents.map(x => { return {...x}}).slice(0, i+1).filter(x => x.data.temperature !== null);
            if (newDeviceEvents.length > 0) {
                avgTemp = Math.round(newDeviceEvents.reduce((total, e) => total + e.data.temperature, 0) / newDeviceEvents.length.toFixed(2) * 100) / 100;
            }
        
            if (event.battery !== null &&
                event.battery <= BatteryMin && 
                event.battery !== prevBattery) {
                if (prevBattery === null ||
                    (event.battery < prevBattery && prevBattery - event.battery >= BatteryArbitrary) || 
                    (event.battery > prevBattery && event.battery - prevBattery >= BatteryArbitrary)) {
                        battery = event.battery;
                        prevBattery = event.battery;
                }
            }
            if (event.gForce !== null && event.gForce >= GForceArbitrary && event.gForce !== prevGForce) {
                prevGForce = event.gForce;
                gForce = event.gForce;
            }

            if (event.gForce !== null && event.gForce >= GForceArbitrary && event.gForce !== prevGForce) {
                prevGForce = event.gForce;
                gForce = event.gForce;
            }

            if (event.temperature !== null && event.temperature !== preTemp && (
                event.temperature >= avgTemp + TempArbitrary || event.temperature <= avgTemp - TempArbitrary)) {
                    preTemp = event.temperature;
                    temp = event.temperature;   
            }
            if (event.lightLevel !== null && event.lightLevel >= LightLevelArbitrary && event.lightLevel !== prevLightLevel) {
                prevLightLevel = event.lightLevel;
                lightLevel = event.lightLevel;
            }

            if (battery !== null || gForce !== null || temp !== null || lightLevel !== null) {
                events[i].data = {
                    id: event.id,
                    passThreshold: true,
                    battery: battery,
                    gForce: gForce,
                    temperature: temp,
                    avgTemp: avgTemp,
                    lightLevel: lightLevel,
                    latitude: event.latitude,
                    longitude: event.longitude,
                };
            }
        }
        return events;
    }
    
    const onChangeDateTimeFilter = (dateRange) => {
        if (dateRange[0] !== undefined) {
            setEndDate(dateRange[0].format());
            setStartDate(dateRange[0].format());
        }
        if (dateRange[1] !== undefined) {
            setEndDate(dateRange[1].format());
        }
    }

    const getFilteredDeviceEvents = () => {
        var filteredEvents = [];
        var allEventsInTimeFrame = [];
        if (startDate !== null) {
            var fromDate = startDate;
            var toDate = endDate;
            if (timePickerCollapsed) {
                fromDate = moment(fromDate).local().format('MMM DD, YYYY 00:00');
                toDate = moment(toDate).local().format('MMM DD, YYYY 23:59');
            }
            allEventsInTimeFrame = allSanitizedEvents.filter(e => 
                moment(moment(e.eventDateTime).local().format(`MMM DD, YYYY HH:mm`))
                .isBetween(fromDate, toDate, undefined, '[]'));
        } else {
            allEventsInTimeFrame = [...allSanitizedEvents]
        }

        const ids = eventTypes.filter(e => e.checked && !e.disabled).map(u => {return u.id});
        if (ids.length > 0) {
             // Get all events in the time frame that pass our event thresholds
            const events = [...allEventsInTimeFrame.filter(e => e.data.passThreshold === true)];
            if (ids.includes(EventType.Battery)) {
              filteredEvents.push.apply(filteredEvents, events.filter(e => e.data.battery !== null))
            }
            if (ids.includes(EventType.GForce)) {
              filteredEvents.push.apply(filteredEvents, events.filter(e => e.data.gForce !== null))
            }
            if (ids.includes(EventType.LowTemp)) {
              filteredEvents.push.apply(filteredEvents, events.filter(e => e.data.temperature !== null && e.data.temperature < e.data.avgTemp))
            }
            if (ids.includes(EventType.HighTemp)) {
              filteredEvents.push.apply(filteredEvents, events.filter(e => e.data.temperature !== null && e.data.temperature > e.data.avgTemp))
            }
            if (ids.includes(EventType.LightLevel)) {
              filteredEvents.push.apply(filteredEvents, events.filter(e => e.data.lightLevel !== null))
            }
        }

        if (isShowedPath) {
            setFilteredDeviceEvents([...allEventsInTimeFrame]);
        } else if (ids.length > 0) {
            setFilteredDeviceEvents([...filteredEvents])
        } else if (ids.length === 0) {
            setFilteredDeviceEvents([]);
        }
        setForcedRefresh(false);
    }
    
    const CustomCalPlugin = ({ DatePicker }) => {
        return <div className='time-selection'
                    onClick={() => {
                        setTimePickerCollapsed(!timePickerCollapsed); 
                        DatePicker.openCalendar()
                    }
            }>
            <FontAwesomeIcon icon={timePickerCollapsed ? faPlus : faMinus}/> 
            <span className={`p-2 ${timePickerCollapsed ? "isCollapsed" : ""}`}>{timePickerCollapsed ? "Select" : "Deselect"} time</span>
        </div>;
    }

    const TrackingInfoListPanel = () => {
        return <div className='tracking-info-panel m-2'>
            <div onClick={() => setIsCollapsed(!isCollapsed)} className='collapsed-panel'>
                <FontAwesomeIcon icon={!isCollapsed ? faAngleLeft : faAngleRight} />
                <span className='collapse-panel-span'>{isCollapsed ? "Show" : "Hide"} tracking info</span>
            </div>
            {!isCollapsed &&
                <div className="tracking-container">
                    <div className={`dest-address-container sticky`}>
                        <p id="destination-label">Delivery Address</p>
                        <p id="delivery-address">{deliveryAddress ? deliveryAddress.address : "N/A"}</p>
                    </div>
                    {puroTrackingInfo?.map((tracking, i) => (
                        <div key={i} className='tracking-details' ref={i === 0 ? refScrollUp : (i === puroTrackingInfo.length - 1 ? refScrollDown : null)}
                            >
                            {moment(tracking.eventDateTime).format('MMM DD') !== (i > 0 ? moment(puroTrackingInfo[i - 1].eventDateTime).format('MMM DD') : "") ?
                                <p id="tracking-datetime">{moment(tracking.eventDateTime).format('MMM DD, YYYY')}</p>:<></>
                            }
                            {tracking.data &&
                                <div sm={8} className="tracking-scan-event">
                                    <p id="tracking-desc">{tracking.data.description ?? ""}</p>
                                    <p id="tracking-location">{tracking.data.depot ?? ""}</p>
                                </div> 
                            }
                        </div>
                    ))}
                    <div className={`scroll-up-button`} onClick={(event) => {handleScrollUp(event)}}><FontAwesomeIcon icon={faCircleArrowUp} /></div>
                </div>
            }
        </div>
    }

    const onSelectEvents = (itemIndex, isChecked) => {
        const updatedListOfItems = [...eventTypes];
        updatedListOfItems[itemIndex].checked = isChecked;
        setEventTypes(updatedListOfItems);
    }

    const onSelectAll = (isSelectedAll) => {
        var updatedListOfItems = [];
        updatedListOfItems = eventTypes.map(e => { if (!e.disabled) {e.checked = isSelectedAll}; return e });
        setEventTypes([...updatedListOfItems]);
    }

    const groupDeviceEvents = (originEvents) => {
        // If there are multiple events at the same geolocation
        //-- Only show the event that passed the threshold
        //-- If there is no event that passed the threshold, then show the first event
        var events = [];
    
        let geoGroups = originEvents.reduce((group, item) => {
          let geolocation = `${item.data.latitude}-${item.data.longitude}`;
          if (!group[geolocation]) {
              group[geolocation] = [];
          }
          group[geolocation].push(item);
          return group;
        }, []);
    
        if (geoGroups) {
            for (var geo in geoGroups) {
              if (geoGroups[geo].length === 1) {
                events.push(geoGroups[geo][0]);
              } else {
                const filteredEvents = geoGroups[geo].filter(e => e.data.passThreshold === true);
                if (filteredEvents.length !== 0) {
                  filteredEvents.map(i => (
                    events.push(i)
                  ));
                } else {
                  events.push(geoGroups[geo][geoGroups[geo].length - 1]);
                }
              }
            }
        }
        return (events.map(x => { return {...x}}))
    }

    const handleSearchQueryChanged = () => {
        if (shipments.length > 0) {
            queryParameters.set("shipmentId",  !selectedShipment ? shipments[0].id : selectedShipment.id);
            if (isShowedPath) {
                queryParameters.set("showedPath",  isShowedPath);
            }
            const eventIds = eventTypes.filter(e => e.checked && !e.disabled).map(u => {return u.id});
            if (eventIds.length > 0) {
                queryParameters.set("filters",  eventIds.join());
            }
            if (startDate !== undefined && startDate !== null && startDate !== minDate && 
                endDate !== undefined && endDate !== null && endDate !== maxDate) {
                queryParameters.set("range", `${startDate.toString().trim()}-${endDate.toString().trim()}`);;
            }
            setSearchParams(queryParameters);
        }
    }

    const resetAllFilters = () => {
        if (isShowedPath) {
            setIsShowedPath(false);
        }
        if (eventTypes.filter(e => e.checked && !e.disabled).length > 0) {
            setEventTypes([...eventTypes.map(e => { if (!e.disabled) {e.checked = false}; return e })]);
        }
        if (startDate !== minDate || endDate !== maxDate) {
            setStartDate(minDate);
            setEndDate(maxDate);
            setDateRange([minDate, maxDate]);
        }
        setForcedRefresh(true);
    }

    useEffect(() => {
        // Handle search query when refresh page
        var shipmentId = currentQueryParameters.get("shipmentId");
        if (shipmentId !== null && shipmentId !== undefined) {
            shipmentId = Number(shipmentId);
            if (shipmentId > 0) {
                const shipment = shipments.filter(s => s.id === shipmentId);
                if ((selectedShipment !== null && shipment[0] !== selectedShipment) || selectedShipment === null) {
                    setSelectedShipment(shipment[0]);
                }
            }
        }
        if (currentQueryParameters.get("showedPath") !== null) {
            setIsShowedPath(true);
        }
        const filters = currentQueryParameters.get("filters");
        if (filters!== null && filters.length > 0) {
            setEventTypes([...eventTypes.map(e => { if (filters.includes(e.id)) {e.checked = true}; return e })]);
        }

        refScrollDown.current?.lastElementChild?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "end"
        });
        const range = currentQueryParameters.get("range");
        var eventMinDate;
        var eventMaxDate;

        const sortedEvents = deviceEvents.sort(function(a,b) {
            return new Date(a.eventDateTime) - new Date(b.eventDateTime);
        })
        if (sortedEvents.length > 0) {
            eventMinDate = moment(sortedEvents[0].eventDateTime).format('MMM DD, YYYY HH:mm');
            eventMaxDate = moment(sortedEvents[sortedEvents.length - 1].eventDateTime).format('MMM DD, YYYY HH:mm');
            setMinDate(eventMinDate);
            setMaxDate(eventMaxDate);
        }

        if (range !== null) {
            var dates = range.split('-', 2);
            const fromDate = moment(dates[0]).format('MMM DD, YYYY HH:mm');
            const endDate = moment(dates[1]).format('MMM DD, YYYY HH:mm')
            setStartDate(fromDate)
            setEndDate(endDate)
            setDateRange([fromDate, endDate]);
        } else {
            setStartDate(eventMinDate);
            setEndDate(eventMaxDate);
            setDateRange([eventMinDate, eventMaxDate]);
        }
        
        const sanitizedEvents = getSanitizedDeviceEvents(deviceEvents);
        setAllSanitizedEvents(groupDeviceEvents(sanitizedEvents));
        setForcedRefresh(true);
    }, [deviceEvents]);

    useEffect(() => {
        handleSearchQueryChanged();
        getFilteredDeviceEvents();
    }, [eventTypes, allSanitizedEvents, isShowedPath]);

    useEffect(() => {
        handleSearchQueryChanged();
        callback(selectedShipment)
    }, [selectedShipment]);

    if (shipments.length > 0) {
        return <div className='m-0 p-0 position-relative z-0'>
            <div className='tracking-maps-control'>
                {!isCollapsed && <TrackingInfoListPanel/>}
                <div className='d-flex flex-wrap control'>
                    {isCollapsed && <TrackingInfoListPanel/>}
                    <Dropdown className="pin-dropdown-button m-2">
                        <Dropdown.Toggle> PIN 
                                <span id="pin-dropdown-title">{!selectedShipment ? shipments[0].pin : selectedShipment.pin}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu id="pin-dropdown-menu" >
                                {shipments.map((shipment, i) => (
                                    <Dropdown.Item key={i}
                                        className={`pin-dropdown-item ${i === 0 ? "item-border-top" : i === shipment.length - 1 ? "item-border-bottom" : ""}`}  
                                        onClick={() => {setSelectedShipment(shipment)}} 
                                        >
                                        <p>{shipment.pin}</p>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    <div className='event-date-picker m-2' onClick={() => datePickerRef.current.openCalendar()}>
                        <span>From</span>
                        <DatePicker
                            ref={datePickerRef}
                            editable={false}
                            onClose={() => {
                                handleSearchQueryChanged();
                                getFilteredDeviceEvents()
                            }}
                            inputClass={`datetime-input-picker ${timePickerCollapsed ? "collapsed" : ""}`}
                            format={`MMM DD, YYYY ${!timePickerCollapsed ? "HH:mm" : ""}`}
                            range
                            rangeHover
                            dateSeparator='   '
                            value={dateRange}
                            onChange={dateObjects => onChangeDateTimeFilter(dateObjects)}
                            minDate={minDate}
                            maxDate={maxDate}
                            plugins={[
                                <CustomCalPlugin position="bottom"/>,
                                <TimePicker 
                                    disabled={timePickerCollapsed}
                                    position="bottom"
                                    hideSeconds={true}
                                    header={true}/>,
                                <DatePanel 
                                    markFocused
                                    removeButton={false}
                                />
                            ]}
                        />
                        <span>To</span>
                        <span className='end-date-picker'>{moment(endDate).format(`MMM DD, YYYY ${!timePickerCollapsed ? "HH:mm" : ""}`)}</span>
                        <Image fluid src={calendarIcon} className='w-24'></Image>
                    </div>          
                    <div className='filters-dropdown-container d-flex m-2'>
                        <Dropdown className="filters-dropdown-button" autoClose="outside">
                            <Dropdown.Toggle className='filters-toggle'> 
                                <span>Filters</span>
                                <span className='filters-count'>{eventTypes.filter(e => e.checked && !e.disabled).length > 0 ? 
                                `+` + eventTypes.filter(e => e.checked && !e.disabled).length : ""}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu id="filters-dropdown-menu" >
                                {eventTypes.map((event, index) => (
                                    <div className='custom-event-option' key={index}
                                        onClick={() => {
                                            if (!event.disabled) {
                                                onSelectEvents(index, !event.checked)}
                                            }}>
                                        <div className='d-flex'>
                                            <div className='filters-menu-icon'>
                                                <Image fluid src={event.icon} className='w-24'></Image>
                                            </div>
                                            <p className='m-0'>{event.desc}</p>
                                        </div>
                                        <Form.Check
                                            disabled={event.disabled}
                                            className="filters-custom-checkbox"
                                            key={event.id} 
                                            type="checkbox"
                                            id={`option_${event.id}`} 
                                            checked={event.checked}
                                            onChange={() => {onSelectEvents(index, event.checked)}}
                                            value={event.id} 
                                        /> 
                                    </div>
                                ))}
                                <div className='filters-dropdown-footer'>
                                    <div onClick={() => onSelectAll(true)} 
                                        className={`filters-select-all ${eventTypes.filter(e => e.checked).length === eventTypes.length ? "disabled" : ""}`}
                                    >
                                        Select All
                                    </div>
                                    <div onClick={() => onSelectAll(false)} 
                                        className={`filters-clear ${eventTypes.filter(e => e.checked).length === 0 ? "disabled" : ""}`}
                                    >
                                        Clear
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <FilteringOptionInfo className="m-2"/>
                    </div>
                    <div className='show-path m-2'>
                        <span>Show journey</span>
                        <ToggleSlider 
                            checked={isShowedPath} 
                            callback={(value) => {
                                setIsShowedPath(value);
                        }}/>
                    </div>
                    {(isShowedPath || eventTypes.filter(e => e.checked && !e.disabled).length > 0 || 
                        (startDate !== null && startDate !== minDate) || (endDate !== null && endDate !== maxDate)) &&
                        <div className="reset-button m-2" onClick={() => resetAllFilters()}>
                            <span> <FontAwesomeIcon icon={faArrowsRotate}/>Reset filters</span>
                        </div>
                    }
                </div>
           </div>
            
            {hasTrackingInfo &&
                <Col className='google-maps-col'>
                    <GoogleMapsContainer
                        forcedRefresh={forcedRefresh}
                        latestEvent={deviceEvents !== null && deviceEvents.length > 0 ? deviceEvents[deviceEvents.length - 1] : null}
                        showedPath={isShowedPath}
                        filters={eventTypes.filter(e => e.checked && !e.disabled).map(u => {return u.id})}
                        deviceEvents={filteredDeviceEvents}
                        deliveryAddress={deliveryAddress}
                    />
                </Col>}
        </div>
    } else {
        return <>
            <div className={`tracking-list-view ${shipments.length === 0 ? "empty" : ""}`}>
                <NoDataFoundContainer/> 
            </div>
        </>
    }
}

export { TrackingInfoMapView }