import React from 'react'
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Dashboard } from './Dashboard/Dashboard';
import { LandingPage } from './components/LandingPage.jsx';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import  DeviceList  from './DeviceList/DeviceList';
import { NotFoundPage } from './NotFoundPage/NotFoundPage.jsx';
import { Login } from './Login/Login.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DeviceDetails } from './DeviceDetails/DeviceDetails.jsx';

const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LandingPage />} />
            <Route path="/" element={<LandingPage />}>
              <Route path="*" element={<NotFoundPage />} />
              <Route index="true" element={<DeviceList />} />
              <Route path="/devices" element={<DeviceList />} />
              <Route path="/devices/:deviceId" element={<DeviceDetails />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;